<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item
                :to="{
                    path: '/academic_Management/academic_font_management/academic_font_management_home',
                }"
                >学术系统</el-breadcrumb-item
            >
            <el-breadcrumb-item>蜂Talk管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">条件筛选</span>
                </el-col>
            </el-row>
            <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
                <el-row :gutter="50">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择类型"
                                style="width: 100%"
                                v-model="queryForm.season"
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <el-col :span="6">
                        <el-form-item label="赛程" prop="raceSchedule">
                            <el-select
                                v-model="queryForm.raceSchedule"
                                placeholder="请选择"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_matchType"
                                    :key="item.id"
                                    :label="item.scheduleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <el-col :span="6">
                        <el-form-item label="组别" prop="level">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.level"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_Group"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="queryForm.name"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="searchResult"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="primary"
                        plain
                        style="width: 100%"
                        @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <!-- 列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="5">
                    <span class="title-class title_class">试卷列表</span>
                </el-col>
                <el-col :span="19" style="text-align: right">
                    <el-button type="primary" @click="addExamDataClicked"
                        >新增试卷+</el-button
                    >
                    <el-button type="primary" @click="secondedClicked"
                        >复议单词</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="赛季" prop="season"> </el-table-column>
                <el-table-column
                    label="赛程"
                    prop="raceScheduleName"
                ></el-table-column>
                <el-table-column
                    label="组别"
                    prop="level"
                    :formatter="groupFormatter"
                ></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="操作" width="120px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            @click="editDistrictMessage(scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-s-grid"
                            size="mini"
                            @click="turnDetailData(scope.row)"
                            >详情</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <el-dialog
            title="编辑试卷信息"
            :visible.sync="editPaperDialogVisible"
            width="30%"
            @close="editPaperDialogVisibleClose"
        >
            <el-form
                :model="editQueryForm"
                label-width="100px"
                ref="editPaperInfoFormRef"
                :rules="editPaperInfoFormRules"
                style="width: 100%"
            >
                <el-form-item label="赛季" prop="season">
                    <el-select
                        placeholder="请选择赛季"
                        style="width: 100%"
                        v-model="editQueryForm.season"
                    >
                        <el-option
                            v-for="item in dict_season"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="赛程" prop="raceSchedule">
                    <el-select
                        v-model="editQueryForm.raceSchedule"
                        placeholder="请选择赛程"
                        style="width: 100%"
                        ><el-option
                            v-for="item in dict_matchType"
                            :key="item.id"
                            :label="item.scheduleName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="试卷组别" prop="level">
                    <el-select v-model="editQueryForm.level">
                        <el-option
                            v-for="item in dict_Group"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="试卷类型" prop="type">
                    <el-select v-model="editQueryForm.type">
                        <el-option
                            v-for="item in questionPaper_type"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="试卷名称" prop="name">
                        <el-input
                            placeholder="请输入"
                            v-model="editQueryForm.name"
                        ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editPaperDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addQuestionPaperDataClicked"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getCityQuestionPaper, deleteCityQuestionWord, cityQuestionPaper, editCityQuestionPaper } from '@/http/api'
export default {
  components: {
  },
  data () {
    return {
      isEdit: false,
      dataList: [],
      raceScheduleList: this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.raceScheduleList = value }),
      dict_matchType: this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.dict_matchType = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_Group: this.$userInfo.dict_group(),
      questionPaper_type: this.$userInfo.questionPaper_type(),
      total: 0,
      editPaperDialogVisible: false,
      editPaperInfoFormRules: {
        season: [{ required: true, message: '请选择', trigger: 'change' }],
        raceSchedule: [{ required: true, message: '请选择', trigger: 'change' }],
        difficulty: [{ required: true, message: '请选择', trigger: 'change' }],
        level: [{ required: true, message: '请选择', trigger: 'change' }],
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      editQueryForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        raceSchedule: '7',
        activityType: this.$chnEngStatusCode.activityType,
        difficulty: null,
        level: null,
        name: '',
        type: null
      },
      queryForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        raceSchedule: '7',
        name: '',
        level: null,
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created () {
    this.searchResult()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    searchResult () {
      getCityQuestionPaper(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 10
      this.searchResult()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.searchResult()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.searchResult()
    },
    // 添加试卷点击
    addExamDataClicked () {
      this.isEdit = false
      this.editPaperDialogVisible = true
    },
    // 编辑点击事件
    editDistrictMessage (rowData) {
      this.isEdit = true
      this.editPaperDialogVisible = true
      this.$nextTick(() => { // 注意看这里
        this.editQueryForm = { ...rowData }
        this.editQueryForm.raceSchedule = this.editQueryForm.raceSchedule + ''
      })
    },
    addQuestionPaperDataClicked () {
      this.$refs.editPaperInfoFormRef.validate(valid => {
        if (!valid) return
        if (this.isEdit === true) {
          editCityQuestionPaper(this.editQueryForm).then((res) => {
            this.editPaperDialogVisible = false
            this.searchResult()
          }).catch((err) => {
            console.log('返回的err', err)
          })
        } else {
          cityQuestionPaper(this.editQueryForm).then((res) => {
            this.editPaperDialogVisible = false
            this.searchResult()
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }
      })
    },
    // 添加试卷关闭
    editPaperDialogVisibleClose () {
      this.$refs.editPaperInfoFormRef.resetFields()
    },
    // 删除
    deleteDialog (rowId) {
      this.$confirm('是否确认删除该试卷?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        deleteCityQuestionWord({ id: rowId }).then((res) => {
          this.searchResult()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 跳转详情
    turnDetailData (rowData) {
      this.$router.push({
        path: '/academic_Management/academic_font_management/academic_examination_fengtalk',
        query: {
          paperId: rowData.id,
          level: rowData.level
        }
      })
    },
    secondedClicked () {
      this.$router.push({
        path: '/academic_Management/academic_font_management/academic_examination_second'
      }
      )
    },
    closeFn () {
      this.editCharactersDialogVisible = false
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === data.level) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style>
</style>
